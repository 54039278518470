import React from 'react';
// import { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
import logo from '../out-position_logo_black.png';

export function Home () {
  return (
    <div>
        <h2>Ecosystem Tracking and Optimization</h2>
        <br></br>
        <br></br>
        <br></br>
        <div>
          <a
            className="Home-link"
            href="https://out-position.com"
            target="_blank"
            rel="noopener noreferrer"
          > 
            <img src={logo} alt="logo" />
          </a>
        </div>
    </div>
  );
}
