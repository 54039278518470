// Function imports
import React, { useState } from 'react';
import PropTypes from "prop-types";
import axios from 'axios';

// Stylesheet imports
import './Login.css';

async function loginUser(credentials) {
  try {
    // const searchParams = new URLSearchParams(credentials);
    const user = credentials.username;
    const pass = credentials.password;
    const response = await axios.get('/api/login', { params: { username: user, password: pass } });
    // Return the data portion of the response
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export function Login( { setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const tokenResp = await loginUser({
          username,
          password
        });
        setToken(tokenResp.token);
      }

  return(
    <div className='App-login'>
      <h2>Please Log In</h2> 
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={ e => setUserName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={ e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}