// Import App central stylesheet
import './App.css';

// Import third party components
import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

// Import App components
import { Login } from './components/Login.js';
import { Home } from './components/Home.js';
import { Map } from './components/Map.js';
import { Dash } from './components/Dash.js';
import { Grid } from './components/Grid.js';
import { Data } from './components/Data.js';
import { Search } from './components/Search.js';
import { NoMatch } from './components/NoMatch';


function TopNavBar () {
  return (
      <nav className='App-topnav'>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/">Home</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/map">Map</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/dash">Dash</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/grid">Grid</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/data">Data</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "" )} to="/search">Search</NavLink>
      </nav>
  );
}

function writeToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function readToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  // return userToken?.token
  return userToken;
}

function App() {
  // Simple Authentication
  const [token, setToken] = useState(readToken());

  if (token !== "etao1234") {
    return (
      <div className="App">
        <div className="App-header">
          <h1>
            ETAO
          </h1>
        </div>
        <div className='App-body'>
          <Login setToken={setToken}/>
        </div>
      </div>
    )
  } else writeToken(token);

  return (
    <div className="App">
      <div className="App-header">
        <h1>
          ETAO
        </h1>
        {<TopNavBar />}
      </div>
      <div className='App-body'>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/map" element={<Map />} />
          <Route path="/dash" element={<Dash />} />
          <Route path="/grid" element={<Grid />} />
          <Route path="/data" element={<Data />} />
          <Route path="/search" element={<Search />} />
          <Route path="/*" element={<NoMatch />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
