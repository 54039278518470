import image from './map-homecare.jpeg';
import ImageMapper from 'react-img-mapper';

const MAP = {
    name: 'povMap',
    areas: [
        {
          "id": "1",
          "href": "/grid",
          "title": "Hardwood",
          "shape": "poly",
          "name": "1",
          "fillColor": "#eab54d4d",
          "strokeColor": "black",
          "coords": [
            813,533,812,448,878,461,935,496,874,557
          ],
          "polygon": [
            [813,533],[812,448],[878,461],[935,496],[874,557]
          ]
        },
        {
            "id": "2",
            "href": "/data",
            "title": "Hardwood",
            "shape": "poly",
            "name": "1",
            "fillColor": "#eab54d4d",
            "strokeColor": "black",
            "coords": [
                876,557,938,500,970,543,989,618,904,619
            ],
            "polygon": [
              [876,557],[938,500],[970,543],[989,618],[904,619]
            ]
          }
    ]
};


export function Map () {
  return (
    <div>
        <h2>Map View</h2>
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            {/* <ImageMapper src={image} map={MAP} onClick={(area) => window.location = "/grid"}></ImageMapper> */}
            {/* <ImageMapper src={image} map={MAP} onClick={(area) => push.history("/grid")}></ImageMapper> */}
            <ImageMapper src={image} map={MAP} width={800} height={600} imgWidth={1600}></ImageMapper>
        </div>
    </div>
  );
}