import '../App.css';
import React from 'react';
import { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
// import ReactEcharts from "echarts-for-react";
import axios from 'axios';

export function Data () {
  
  const [results, setResults] = useState([]);
  
  // Want to use async/await? Add the `async` keyword to your outer function/method.
  async function getData(apiURL) {
    try {
      const response = await axios.get(apiURL);
      setResults(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  /* const fetchData = async (apiURL) => {
    try { 
      const response = await fetch(apiURL);
      const data = await response.json();
      setResults(data);
      console.log(response);
    } 
    catch (error) { 
      console.log(error);
    } 
  }; */
  
  /* This works
  useEffect(() => {
    const fetchData = async () => {
      try { 
        const response = await fetch('/api/hello');
        const data = await response.json();
        setResults(data);
      } 
      catch (error) { 
        return error;
      } 
      finally {
        console.log("All done.");
      }
    };
    fetchData();
  }, []);
  */

  useEffect(() => { getData("/api/opkvn?key=grid") }, []);

  /*
  //Convert a JSON object to JS array
  let resultArr = [];
  let keys = Object.keys(results);
  keys.forEach(function(key){
      resultArr.push(results[key]);
  }); 
  */

  return (
    <div>
        <h2>Data View</h2>
        <p>Table</p>
        <table className='etaoTable'>
          <tbody>
            <tr>
              <th>Name</th>
              <th>x-Axis</th>
              <th>y-Axis</th>
            </tr>
            { results.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.value[0]}</td>
                    <td>{item.value[1]}</td>
                  </tr>
              );
              })
            }
          </tbody>
        </table>
    </div>
  );
}
