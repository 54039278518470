import '../App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';

export function Search () {
  
  const [search, setSearch] = useState([]);
  // const [results, setResults] = useState([]);
  const [total, setTotal] = useState([]);
  const [items, setItems] = useState([]);

  // Want to use async/await? Add the `async` keyword to your outer function/method.
  async function getData(searchString) {
    // DEBUG: console.log(searchString);
    try {
      const response = await axios.get(searchString);
      // Set value of the return to be used
      // setResults(response.data);
      setTotal(response.data.searchInformation.formattedTotalResults);
      setItems(response.data.items);
      // DEBUG: console.log(results);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    // DEBUG: console.log(search);
    getData("/api/search?search=" + search);
  }

  // Set initial values to avoid errors on first render with ", []"
  useEffect(() => {
    setTotal(0);
    setItems([]);
  }, []);

  return (
    <div>
        <h2>Search </h2>
        <h3>Input</h3>
        <form onSubmit={handleSubmit}>
        <label>
          <p>Search Term</p>
          <input type="text" name="search" onChange={ e => setSearch(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <div>
        <h3>Output</h3>
        <div id="TextBox">
          <p>Total Resutls: { total }</p>
          <p>Top Ten Results:</p>
          <table className='etaoTable'>
          <tbody>
            <tr>
              <th>Name</th>
              <th>URL</th>
            </tr>
            { 
              items.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.title}</td>
                    <td><a target="_blank" rel="noreferrer" href={item.formattedUrl}>{item.formattedUrl}</a></td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
}