import React from 'react';
// import { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
import ReactEcharts from "echarts-for-react";
import axios from 'axios';

export function Grid() {
    
    const option = {
        xAxis: {},
        yAxis: {},
        series: [
          {
            symbolSize: 20,
            data: [
              { name: "A.1", value: [10.0, 8.04] },
              { name: "A.2", value: [8.07, 6.95] },
              { name: "A.3", value: [13.0, 27.58] },
              { name: "B.1", value: [-9.05, 8.81] },
              { name: "B.2", value: [11.0, 8.33] },
              { name: "B.3", value: [-14.0, 57.66] },
              { name: "C.1", value: [13.4, 6.81] },
              { name: "C.2", value: [-10.0, 6.33] },
              { name: "C.3", value: [14.0, 8.96] },
              { name: "D.1", value: [-12.5, 6.82] },
              { name: "D.2", value: [9.15, 7.2] },
              { name: "D.3", value: [-11.5, 7.2] },
              { name: "E.1", value: [3.03, 4.23]} ,
              { name: "E.2", value: [12.2, 17.83] },
              { name: "E.3", value: [-2.02, 4.47] },
              { name: "F.1", value: [1.05, 3.33] },
              { name: "F.2", value: [4.05, 24.96] },
              { name: "F.3", value: [-6.03, 67.24] },
              { name: "G.1", value: [12.0, 6.26] },
              { name: "G.2", value: [12.0, 8.84] },
              { name: "G.3", value: [-7.08, 5.82] }
            ],
            label: { 
              show: true,
              position: 'right',
              formatter: params => params.name,
              color: "black",
              fontSize: 16,
              },
            type: 'scatter'
          }
        ]
      };
    
    async function getData(apiURL) {
      try {
        const response = await axios.get(apiURL);
        return response.data;
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    option.series.data = getData("/api/opkvn?key=grid"); 

    return (
        <div>
            <h2>Grid View</h2>
            <div className='App-chart'>
                <ReactEcharts option={option} />;
            </div>
        </div>
    );
}